body {
  margin: 5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

tr.startedgame {
  background-color: #ddd;
}

td.isbettable {
  background-color: green;
}

td.smallbet {
  background-color: rgb(169, 245, 169);
}

th, td {
  border-bottom: 1px solid #ddd;
  padding-left: 6px;
  padding-right: 4px;
}

.vegas-edge {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.hard-edge {
  border-right: 1px solid black;
}

table { border-collapse: collapse; }

.pinny-cell {
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.toggle {
  margin-bottom: 15px;
}

.toggle button {
  margin-left: -1px;
  width: 115px;
  border: 1px solid grey;
  background-color: rgb(226, 219, 219);
  cursor: pointer;
  font-size: inherit;
  text-align: center;
}

.toggle .selected {
  background-color: #fff;
  font-weight: bold;
  cursor: default;
}

table.odds {
  margin-top: 10px;
    overflow-y:scroll;
    height: 75vh;
    display:block;
    overflow: auto;
}

/* table thead th { position: sticky; top: 0; z-index: 1; } */
table th {
  position: -webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 1; 
  background: #fff; 
}

#settings {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  width: 50vh;
}

#settings div {
  padding-top: 2px;
}

/* td {
  width: 78px;
} */
